import './Hero.scss';

function Hero() {
    return (
        <header className="hero">
            <p>Hello, my name is Amy.</p>
            <p>I'm a full-stack developer located in Vancouver, BC.</p>
            <p>Enjoy my website!</p>

        </header>
    )
}

export default Hero;

